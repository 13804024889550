.pagination-container {
  position:absolute;
  bottom: 20px;
  right: 0;
  left: 0;
  background: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  }

  
  .pagination-info {
  margin: 0 10px;
  }
  
  .pagination-container button {
  margin: 0 5px;
  margin-left: 18%;
  }
  
  .pagination-container button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  margin-left: 21%;
  }

  .pag{
    margin-left: 17%;
  }
  .anterior{
    margin-left: 30px;
  }

  .table{
    width: 100%;
  border-collapse: collapse;
  }
