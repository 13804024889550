
.sidebar {
    position:fixed;
    z-index: 1;
    top: 0;
    left: 0;
    height: 110vh;
    background-color: #0F1A34;

    &bars{
        font-size: 25px;
        margin-left: 50%;
    }
    

    &__logo {
        display: grid;
        place-items: center;
        height: 120px;
        font-size: 1.5rem;
        font-weight: 700;
        font-family: 'Mochiy Pop P one', sans-serif;
        color: #fff;
        background-color: #182444;
    }

    &__menu {
        position: relative;

        &__item {
            display: flex;
            align-items: center;
            place-content: flex-start;
            padding: 1rem 2rem;
            font-size: 1.25rem;
            font-weight: 500;
            color: #FFF;
            transition: color 0.3s ease-in-out;

            &.active {
                color: #fff;
            }

            &__icon {
                margin-right: 1rem;

                i {
                    font-size: 1.75rem;
                }
            }
        }

        &__indicator {
            position: absolute;
            left: 50%;
            width: calc(100% );
            background-color: #091023;
            border-radius: 10px;
            z-index: -1;
            transition: 0.3s ease-in-out;
        }
    }

    .sidebar__menu {
        width: 230px;
        transition: width 0.3s ease-in-out;
    }
    
    .sidebar__menu.collapsed {
        width: 100px;
    }

    .sidebar__menu__item__icon {
        display: flex;
      }
      
      .sidebar__menu__item__text {
        display: none;
      }

      .collapsed .sidebar__logo{
        display: none;
      }
      
      .collapsed .sidebar__menu__item__icon {
        display: flex;
      }
      
      .collapsed .sidebar__menu__item__text {
        display: none;
      }
      
      .sidebar__menu:not(.collapsed) .sidebar__menu__item__icon {
        display: flex;
      }
      
      .sidebar__menu:not(.collapsed) .sidebar__menu__item__text {
        display: block;
      }
      .sidebar__toggle-button{
        margin-top: 5%;
        color: #FFF;
        margin-left: 32px;
        margin-bottom: 10%;
      }
}
