.listado{
    overflow-y: scroll;
    overflow-x: hidden;
}

.listado::-webkit-scrollbar{
    width: 10px;
    background-color: #f8f8f8;
    border-radius: 10px;
}

.listado::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(184, 184, 184);
}

.btnr{
    margin-left: 5%;
}

